<template>
    <div class="w-full flex-auto flex flex-col z-0 py-1 lg:py-2"> 
        <div class="max-w-screen-xl px-2 md:px-6 lg:px-8 w-full mx-auto z-40 py-1 md:py-2">
            <div class="block md:flex shadow-md p-4">
                <div class="md:w-1/4">
                    <img class="rounded-full w-1/2 mx-auto" src="https://www.genesisonlineuniversity.com/images/avatar.jpg" alt="Profile">
                </div>
    
                <div class="w-full">
                    <div class="w-full md:flex flex-col gap-x-11 gap-4">
                        <div class="md:flex text-center md:justify-between">
                            <h2 class="text-md md:text-2xl text-blue-500 font-bold ">{{ $store.getters["student/name"] }}</h2>
                            <router-link to="/my/profile" class="xm:w-full xm:m-4 text-xs md:text-lg text-center md:font-bold cursor-pointer border border-purple-500 text-purple-600 hover:bg-purple-500 hover:text-white rounded-lg px-3 md:py-1.5">Edit Profile</router-link>
                        </div>
    
                        <div class="max-w-xs flex justify-between text-sm md:text-md xm:my-2">
                            <span class="">
                                10 posts
                            </span>
                            <span class="">
                                2 followers
                            </span>
                            <span class="">
                                0 following
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-center mt-5">
                <router-link to="/my/create-blog" class="p-3 bg-blue-500 text-white font-semibold rounded-full">Create a New Post</router-link>
            </div>
        </div>

        <div class="max-w-screen-xl px-2 md:px-6 lg:px-8 w-full mx-auto z-40 py-1 md:py-2">
            <div class="md:flex md:flex-row">
                <div iv="" class="md:w-1/3 h-auto">
                    <div class="shadow-md block p-4 gap-4 mb-4">
                        <h2 class="w-1/3 md:text-2xl md:font-blod text-blue-500 gap-4">
                            Intro
                        </h2>
                        <profile-item @updated="dataUpdated" name="name" label="Name" :val="profile_data.name" :editable="false"/>
                        <profile-item @updated="dataUpdated" name="phone" label="Phone" :val="profile_data.phone" :editable="false"/>
                        <profile-item @updated="dataUpdated" name="email" label="Email" :val="profile_data.email" :editable="false"/>
                        <profile-item @updated="dataUpdated" name="date_of_birth" label="Date of Birth" :val="profile_data.dateOfBirth" :editable="false"/>
                    </div>
                </div>

                <div class="shadow-md">
                    <main-blog :posts="profiles"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import mainBlog from './main-blog.vue';
import ProfileItem from "../../profile/components/profile-item.vue";

export default {
    name: 'profile',
    components: {
        mainBlog,
        ProfileItem
    },
    data: {
        loading: true,
        profile_data: { }
    },
    computed: {
        ...mapGetters({
            profiles: 'blogs/post_profiles'
        }),
    },
    created() {
        this.$store.dispatch('blogs/postProfileData');

        this.$store.dispatch( 'student/profile', {
            success: ( {data} )=> {
                this.profile_data = data.student;
            }
        }).finally( () => this.$emit( 'loaded' ) );
    },
}
</script>

<style lang="">
    
</style>