<template>
    <div class="md:px-2 col-span-full md:col-span-2 flex flex-col order-3 md:order-2">

        <div class="px-2 py-2 mb-3 bg-white shadow rounded-lg" v-for="post in posts" :key="post.id">

            <div class="flex relative">

                <img class="w-12 h-12 rounded-full" v-bind:src="post.postableImage" />

                <div class="ml-2 mt-0.5">
                    <span class="block font-semibold">{{ post.postableName }}</span>
                    <span class="block text-sm font-sans text-gray-500">{{ post.date }} at {{ post.time }}</span>
                </div>

                <div class="absolute inset-y-2 right-0 flex">
                    <div v-if="post.views" class="flex flex-row bg-gray-200 rounded-md px-2 py-1">
                        <p class="font-semibold">{{ post.views }} </p>
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 ml-1" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                <path stroke-linecap="round" stroke-linejoin="round" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                            </svg>
                        </span>    
                    </div>
                </div>

            </div>

            <p class="overflow-hidden text-blue-500 mt-3 text-justify" v-html="post.title"></p>
            <p class="overflow-hidden text-gray-700 hidden text-justify" v-html="post.summary"></p>

            <p class="ck-content text-justify text-gray-700" v-html="post.body.substring(0, 100) + '...'"></p>
            <router-link :to="`/my/blog/${ post.id }`" class="underline">Read Full Post</router-link>
            
            <img class="object-cover w-full" v-bind:src="post.image"/>

            <div class="flex justify-between items-center border-b">
                <div class="relative">
                    <button class="flex mx-4 md:mx-6 my-2 div" @click="PostLikeHover = PostLikeHover == post.id ? '' :  post.id">
                        <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 md:w-6 md:h-6 mt-0.5 text-blue-600" viewBox="0 0 20 20" fill="currentColor">
                            <path d="M2 10.5a1.5 1.5 0 113 0v6a1.5 1.5 0 01-3 0v-6zM6 10.333v5.43a2 2 0 001.106 1.79l.05.025A4 4 0 008.943 18h5.416a2 2 0 001.962-1.608l1.2-6A2 2 0 0015.56 8H12V4a2 2 0 00-2-2 1 1 0 00-1 1v.667a4 4 0 01-.8 2.4L6.8 7.933a4 4 0 00-.8 2.4z" />
                        </svg>
                        <span class="ml-1 mt-0.5 text-base text-gray-500 font-sans md:font-semibold">{{ post.totalLikes }}</span>
                    </button>

                    <div class="absolute bottom-8 left-0 bg-gray-600 text-white w-60 rounded-md overflow-y-auto h-36" v-show="PostLikeHover == post.id">
                        <div class="flex flex-row py-0.5 px-3 justify-between" v-for="like in post.likes" :key="like.id">
                            <p class="mr-2">{{ like.studentName }}</p>
                            <p class="ml-2">{{ like.icon }}</p>
                        </div>
                    </div>
                </div>

                <div class="flex mx-4 md:mx-6 my-2">
                    <p class="text-gray-500 font-sans">{{ post.totalcomments }} Comments &</p>
                    <span class="ml-2 text-gray-500 font-sans">0 Shares</span>
                </div>
            </div>

            <div class="flex justify-between items-center mt-2 md:mx-10 mx-5">
               

                <div class="relative">
                     <button class="flex"  @click="showLike = showLike == post.id ? '' :  post.id">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 md:h-6 md:w-6" viewBox="0 0 20 20" fill="currentColor">
                            <path d="M2 10.5a1.5 1.5 0 113 0v6a1.5 1.5 0 01-3 0v-6zM6 10.333v5.43a2 2 0 001.106 1.79l.05.025A4 4 0 008.943 18h5.416a2 2 0 001.962-1.608l1.2-6A2 2 0 0015.56 8H12V4a2 2 0 00-2-2 1 1 0 00-1 1v.667a4 4 0 01-.8 2.4L6.8 7.933a4 4 0 00-.8 2.4z" />
                        </svg>
                        <span class="ml-1 text-gray-500 font-semibold">Like</span>
                    </button>

                    <div v-show="showLike == post.id" class="absolute bottom-8 -right-6 flex bg-gray-200 rounded-full text-xl text-white font-sans">
                        <button @click="postLike(1, post.id)" class="px-2 py-1">👍</button>
                        <button @click="postLike(2, post.id)" class="px-2 py-1">😍</button>
                        <button @click="postLike(3, post.id)" class="px-2 py-1">😥</button>
                    </div>
                </div>

                <div class="relative">
                    <button class="flex" @click="showWriteComment = showWriteComment == post.id ? '' :  post.id">
                        <svg xmlns="http://www.w3.org/2000/svg" class=" h-5 w-5 md:h-6 md:w-6 mt-0.5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z" />
                        </svg>
                        <span class="ml-1 text-gray-500 font-semibold">Comments</span>
                    </button>

                    <comment-form class="absolute -left-14" v-show="showWriteComment == post.id" :post="post" @changed="changedPost" :dismissCommentSection="dismissCommentSection" />
                </div>

                <div class="relative">
                    <button class="flex" @click="showShare = showShare == post.id ? '' :  post.id">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 md:h-6 md:w-6" viewBox="0 0 512 512">
                            <path d="M503.7 226.2l-176 151.1c-15.38 13.3-39.69 2.545-39.69-18.16V272.1C132.9 274.3 66.06 312.8 111.4 457.8c5.031 16.09-14.41 28.56-28.06 18.62C39.59 444.6 0 383.8 0 322.3c0-152.2 127.4-184.4 288-186.3V56.02c0-20.67 24.28-31.46 39.69-18.16l176 151.1C514.8 199.4 514.8 216.6 503.7 226.2z"/>
                        </svg>
                        <span class="ml-1 text-gray-500 font-semibold">Share</span>
                    </button>

                    <div v-show="showShare == post.id" class="absolute bottom-8 right-0 flex flex-col bg-white text-white rounded-md font-sans w-36">
                        <button @click="copyTextToClipboard(`http://192.168.88.198:8080/my/blog/${post.id}`)" rel="noopener noreferrer" ref="mylink" class="my-1 mx-1 p-0.5 bg-gray-500 rounded-md"> Copy Link </button>
                        <a :href="`https://facebook.com/sharer.php?display=page&u=http://192.168.88.198:8080/my/blog/${post.id}`" target="_blank" class="my-1 mx-1 p-0.5 bg-gray-500 rounded-md"> Share in Facebook </a>
                        <a :href="`https://twitter.com/intent/tweet?url=http://192.168.88.198:8080/my/blog/${post.id}`" target="_blank" class="my-1 mx-1 p-0.5 bg-gray-500 rounded-md"> Share in Twitter </a>
                        <a :href="`https://www.linkedin.com/shareArticle?mini=true&url=http://192.168.88.198:8080/my/blog/${post.id}`" target="_blank" class="my-1 mx-1 p-0.5 bg-gray-500 rounded-md"> Share in Linkedin </a>
                        <a :href="`https://api.whatsapp.com/send?text=http://192.168.88.198:8080/my/blog/${post.id}`" target="_blank" class="my-1 mx-1 p-0.5 bg-gray-500 rounded-md"> Share in WhatsApp </a>
                    </div>
                </div>
            </div>

            <div class="flex items-center justify-center border rounded-full mt-1">
            </div>

            <div class="flex justify-between items-center">
                <button @click="showAllComment = showAllComment == post.id ? '' :  post.id" class="flex pt-2">
                    <p class="font-medium text-gray-500">View Comments</p>
                </button>
                
                <div class="flex pt-2">
                    <p>All Comments</p>
                </div>
            </div>


            <div class="ml-2" v-show="showAllComment == post.id">
                <div class="justify-start" v-for="comment in post.comments" :key="comment.id">
                    <div class="flex mb-4">
                        <img class="w-9 h-9 rounded-full" v-bind:src="comment.commentableImage" />
                        
                        <div class="bg-gray-100 rounded-2xl  ml-2 p-2">

                            <span class="block font-medium text-black">{{ comment.commentableName }}</span>
                            <span class="block text-sm text-gray-600">{{ comment.body }}</span>
                            <span class="block text-sm text-gray-800 mt-2 text-right font-sans">{{ comment.date }} at {{ comment.time }}</span>

                            <div class="flex -mb-8 justify-end">

                                <div class="relative">

                                    <button class="flex"  @click="showCommentLike = showCommentLike == comment.id ? '' :  comment.id">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 md:h-6 md:w-6" viewBox="0 0 20 20" fill="currentColor">
                                            <path d="M2 10.5a1.5 1.5 0 113 0v6a1.5 1.5 0 01-3 0v-6zM6 10.333v5.43a2 2 0 001.106 1.79l.05.025A4 4 0 008.943 18h5.416a2 2 0 001.962-1.608l1.2-6A2 2 0 0015.56 8H12V4a2 2 0 00-2-2 1 1 0 00-1 1v.667a4 4 0 01-.8 2.4L6.8 7.933a4 4 0 00-.8 2.4z" />
                                        </svg>
                                        <span class="ml-1 text-gray-500 font-semibold">Like {{ comment.totalLikes }}</span>
                                    </button>

                                    <div v-show="showCommentLike == comment.id" class="absolute bottom-8 -right-6 flex bg-gray-200 rounded-full text-xl text-white font-sans">
                                        <button @click="commentLike(1, comment.id)" class="px-2 py-1">👍</button>
                                        <button @click="commentLike(2, comment.id)" class="px-2 py-1">😍</button>
                                        <button @click="commentLike(3, comment.id)" class="px-2 py-1">😥</button>
                                    </div>
                                </div>

                                <div class="relative">
                                    <button class="ml-5 text-gray-500 font-semibold" @click="showReplayComment = showReplayComment == comment.id ? '' :  comment.id"> Reply</button>

                                    <div v-show="showReplayComment == comment.id" class="absolute -right-14 flex items-center justify-center border rounded-full mt-1">
                                        <commentForm class="" :comment="comment" />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div class="mt-7 ml-10">
                        <div class="justify-start" v-for="comment of comment.comments">
                            <div class="flex mb-2">
                                <img class="w-9 h-9 rounded-full" v-bind:src="comment.commentableImage" />

                                <div class="bg-gray-100 ml-2 p-2 rounded-2xl">
                                    <span class="block font-medium text-black font">{{ comment.commentableName }}</span>
                                    <span class="block text-sm text-gray-700">{{ comment.body }}</span>
                                    <span class="block text-sm text-gray-800 mt-2 text-right font-sans">{{ comment.date }} at {{ comment.time }}</span>
                                    
                                    <div class="flex -mb-4 justify-end">
                                        <div class="relative">

                                            <button class="flex"  @click="showCommentLike = showCommentLike == comment.id ? '' :  comment.id">
                                                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 md:h-6 md:w-6" viewBox="0 0 20 20" fill="currentColor">
                                                    <path d="M2 10.5a1.5 1.5 0 113 0v6a1.5 1.5 0 01-3 0v-6zM6 10.333v5.43a2 2 0 001.106 1.79l.05.025A4 4 0 008.943 18h5.416a2 2 0 001.962-1.608l1.2-6A2 2 0 0015.56 8H12V4a2 2 0 00-2-2 1 1 0 00-1 1v.667a4 4 0 01-.8 2.4L6.8 7.933a4 4 0 00-.8 2.4z" />
                                                </svg>
                                                <span class="ml-1 text-gray-500 font-semibold">Like {{ comment.totalLikes }}</span>
                                            </button>

                                            <div v-show="showCommentLike == comment.id" class="absolute bottom-8 -right-6 flex bg-gray-200 rounded-full text-xl text-white font-sans">
                                                <button @click="commentLike(1, comment.id)" class="px-2 py-1">👍</button>
                                                <button @click="commentLike(2, comment.id)" class="px-2 py-1">😍</button>
                                                <button @click="commentLike(3, comment.id)" class="px-2 py-1">😥</button>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 


        </div>
    </div>
</template>

<script>

import commentForm from './comment-form.vue';

export default {
    name: 'main-blog',
    components: {
        commentForm
    },
    data() {
        return {
            showAllComment: "",
            showLike: "",
            showCommentLike: "",
            showShare: "",
            showReplayComment: "",
            showWriteComment: "",
            PostLikeHover: "",
        };
    },
    props: {
        posts: {
            type: Object,
            default: {}
        }
    },
    methods: {
        async copyTextToClipboard(text) {
            try {
                await navigator.clipboard.writeText(text);
            } catch($e) {
                alert($e);
            }
        },
        changedPost( changedPostData ){
            console.log( "changedPostData", changedPostData )
        },

        postLike(type, post_id) {
            this.loading = true;
            this.formErrors = [];

            this.$store.dispatch('blogs/submitLike', {
                data: {
                    type,
                    post_id
                },
            })
            .finally(() => {
                this.loading = false;
                this.showLike = '';
            });
        },
        commentLike(type, comment_id) {
            this.loading = true;
            this.formErrors = [];

            this.$store.dispatch('blogs/submitCommentLike', {
                data: {
                    type,
                    comment_id
                },
            })
            .finally(() => {
                this.loading = false;
                this.showCommentLike = '';
            });
        },
        dismissCommentSection() {
            this.showWriteComment = '';
        }
    }
}
</script>

<style lang="">
    
</style>
