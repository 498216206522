<template>
    <div>
        <form @submit.prevent="submitCommentForm" method="post">
            <div class="flex">
                <input type="text" v-model="commentForm.body" class="px-4 py-2 bg-gray-200 rounded-l-full font-sans" placeholder="Write your comments...">

                <button class="px-4 border rounded-r-full font-sans bg-gray-400">            
                    <svg xmlns="http://www.w3.org/2000/svg" class="" width="24" height="24" viewBox="0 0 24 24"><path d="M2.01 21L23 12 2.01 3 2 10l15 2-15 2z"/></svg>
                </button>
            </div>
        </form>
    </div>
</template>

<script>
export default {
    name: 'commentpform',
    components:{

    },
    data(){
        return {
            commentForm: {
                body: ''
            },
            loading: false,
            formErrors: [],
        }
    },
    props: {
        post: {
            type: Object,
            default: {},
        },
        comment: {
            type: Object,
            default: "",
        },
        dismissCommentSection: Function,
    },
    methods: {
        submitCommentForm() {
            this.loading = true;
            this.formErrors = [];

            this.$store.dispatch('blogs/submitComment', {
                data: {
                    post_id: this.post.id,
                    comment_id: this.comment.id,
                    body: this.commentForm.body
                }
            })
            .finally(() => {
                this.loading = false;
                this.commentForm.body = "";
                this.dismissCommentSection();
            });
        }
    }
}
</script>
